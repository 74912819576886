<template>
  <div>
    <el-card>
      <all-log :dialogLog="dialogLog" />

      <div class="menu-box">
        <h2>站点规格类型管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-button type="primary" @click="pAdd()"
                >添加站点规格类型</el-button
              >
              <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              >
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
          >
            <el-table-column prop="mould_name" label="站点规格类型名称">
            </el-table-column>

            <!-- <el-table-column prop="site_status" label="状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.site_status === 1">
                  启用
                </el-tag>
                <el-tag type="warning" v-if="scope.row.site_status === 0">
                  禁用
                </el-tag>
              </template>
            </el-table-column> -->
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-setting"
                  @click="handleEdit(scope.row)"
                  >编辑规格</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :pager-count="15"
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.query.limit"
            background
            :total="exp.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 添加/编辑对话框 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="formClose"
    >
      <el-form :rules="formRules" ref="formRef" :model="form">
        <el-form-item
          prop="mould_name"
          label="规格类型名称"
          :label-width="formLabelWidth"
        >
          <el-input
            @change="handleChange"
            v-model="form.mould_name"
            placeholder="请填写站点规格类型名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-row v-if="addOrEdit === 2" :gutter="20">
        <el-col :span="4"
          ><el-button
            size="mini"
            type="success"
            @click="addNewSpecLine"
            :disabled="addSpecDisable"
            >+ 新增规格</el-button
          ></el-col
        >
        <el-col :span="20"><span>站点规格示例：个、天、位等等</span></el-col>
      </el-row>
      <el-form :rules="specFormRules">
        <el-table
          v-if="addOrEdit === 2"
          ref="specTableRef"
          :data="specForm.spec_list"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column prop="spec_name" label="规格名称" width="120">
            <template slot-scope="scope">
              <el-form-item class="all">
                <el-input
                  v-if="scope.row.editFlag"
                  clearable
                  class="spec-input"
                  size="mini"
                  v-model="scope.row.spec_name"
                  @change="editSpecName(scope.row, scope.$index)"
                ></el-input>
                <el-input
                  v-else-if="scope.row.addFlag"
                  clearable
                  class="spec-input"
                  size="mini"
                  v-model="scope.row.spec_name"
                  @change="addSpecName(scope.row)"
                ></el-input>
                <span
                  style="cursor: pointer ;"
                  @click="changeToInput(scope.row, scope.$index)"
                  v-else
                  >{{ scope.row.spec_name }}</span
                >
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="item" label="属性">
            <template slot-scope="scope">
              <!-- {{scope.row.item}} -->
              <el-form-item class="inner-form-item">
                <div
                  style="display: inline-block"
                  v-for="item in scope.row.spec_item_list"
                  :key="item.item_id"
                >
                  <!-- 没有id就说明是自己添加的 -->
                  <el-input
                    v-if="!item.item_id || item.editFlag"
                    style="width: 80px; margin-right: 5px"
                    clearable
                    class="spec-input"
                    size="mini"
                    v-model="item.item_name"
                    @change="addSpecitem(item, scope.row, scope.$index)"
                  ></el-input>
                  <el-tag
                    style="margin-right:4px"
                    @close="handleTagClose(item)"
                    @click="changeTagIntoInput(item, scope.row, scope.$index)"
                    closable
                    type="success"
                    size="medium"
                    v-else
                    >{{ item.item_name }}</el-tag
                  >
                </div>
                <div
                  style="width: 60px; display: inline-block; text-align: center"
                >
                  <span
                    v-if="!scope.row.addSpecItemDisable"
                    @click="addNewInput(scope.row, scope.$index)"
                    style="color: #409eff; cursor: pointer"
                  >
                    增加</span
                  >
                  <span
                    v-else-if="scope.row.addSpecItemDisable"
                    style="color: #999; cursor: not-allowed"
                  >
                    增加</span
                  >
                </div>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column width="120" label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="delSpec(scope.$index, scope.row)"
                >删除规格</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-form>

      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAE()">取 消</el-button>
        <el-button type="primary" @click="confirmAE()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getModelList,
  addModel,
  getInfo,
  editModel,
  delModel,
  delSpec,
  addSpec,
  addSpecItem,
  delSpecItem,
  editSpecItem,
  editSpecName,
} from '@/api/edu/servicemodel.js'

export default {
  data() {
    return {
      dialogLog: {
        state: false,
      },
      token: {
        'Accept-Token': window.sessionStorage.getItem('token'),
      },
      specDialogVisible: false,
      isEdit: false,
      tableLoading: false,
      warningText: '',
      exp: {
        total: 0,
      },
      specItemEditFlag: false,
      spcateList: [],
      serviceList: [],
      addSpecDisable: false,
      addSpecItemDisable: false,
      query: {
        page: 1,
        page_code: '',
        page_num: 7,
      },
      optionProps: {
        checkStrictly: false,
        value: 'id',
        label: 'cname',
        children: 'sub',
      },
      dialogVisible: false,

      formRules: {
        mould_name: {
          required: true,
          message: '请输入站点规格类型名称',
          trigger: 'blur',
        },
      },
      specFormRules: {},
      title: '添加站点规格类型',
      addOrEdit: 1,
      formLabelWidth: '120px',
      optionsTask: [],
      optionPropsTask: {
        value: 'id',
        label: 'name',
        children: 'son',
        disabled: 'state',
      },
      isSpecEdit: false,
      fileList: [],
      form: {
        mould_name: '',
      },
      specForm: {},
      tableData: [],
      row: {},
      specNameEditFlag: false,
    }
  },
  created() {
    this.getList()
  },
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl
    },
  },
  methods: {
    pageChange(val) {
      this.query.page = val
      this.getList()
    },
    sizeChange(val) {
      this.query.page_num = val
      this.getList()
    },
    async getList() {
      this.tableLoading = true
      const { data: res } = await getModelList(this.query)

      if (res.code === 1) {
        this.tableData = res.data.data
        this.exp.total = res.data.total
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },

    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    // 取消
    cancelAE() {
      this.title = '添加站点规格类型'
      this.addOrEdit = 1
      this.addSpecDisable = false
      this.form = {
        mould_name: '',
      }
      this.specForm = {}
      this.isEdit = false

      if (this.$refs.formRef) {
        setTimeout(() => {
          this.$refs.formRef.clearValidate()
        }, 30)
      }

      this.dialogVisible = false
    },
    cancelEditSpec() {
      this.sepcform = {}
      if (this.$refs.specFormRef) {
        setTimeout(() => {
          this.$refs.specFormRef.clearValidate()
        }, 30)
      }
    },
    // 新增打开
    pAdd(row) {
      console.log(row)
      //this.getOptionList()
      this.dialogVisible = true
    },
    // 编辑打开
    async handleEdit(row) {
      this.dialogVisible = true
      this.title = '编辑站点详情'
      this.addOrEdit = 2
      this.row = row
      this.refreshEditInfo(row)
    },
    async refreshEditInfo(row) {
      const { data: res } = await getInfo({
        mould_id: row.mould_id,
      })
      this.form.mould_name = res.data.mould_name
      this.form.mould_id = res.data.mould_id
      this.specForm = res.data
    },
    async handleModelEdit(row) {
      this.specDialogVisible = true

      const { data: res } = await getInfo({
        mould_id: row.mould_id,
      })
      this.sepcform = res.data
    },
    // 删除
    remove(row) {
      this.$confirm('确认删除？')
        .then(async (_) => {
          const { data: res } = await delModel({
            mould_id: row.mould_id,
          })
          if (res.code === 1) {
            this.$message.success('删除成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    confirmAE(e) {
      console.log(e)
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          if (this.addOrEdit === 1) {
            // 添加

            const { data: res } = await addModel(this.form)
            if (res.code === 1) {
              this.$message.success('添加成功')
              this.getList()
              this.cancelAE()
            } else {
              this.$message.error(res.message)
            }
          } else {
            // 编辑

            if (this.isEdit === true) {
              const { data: res } = await editModel(this.form)
              if (res.code === 1) {
                this.$message.success('编辑成功')
                this.cancelAE()
                this.getList()
              } else {
                this.$message.error(res.message)
              }
            } else {
              this.$message.info('没有修改规格名')
            }
          }
        }
      })
    },
    addNewSpecLine() {
      const newLine = {
        spec_name: '',
        spec_item_list: [],
        addFlag: true,
      }
      this.addSpecDisable = true
      this.specForm.spec_list.push(newLine)
    },
    addNewInput(row, index) {
      console.log(index)
      console.log(this.specForm.spec_list[index])
      this.$set(this.specForm.spec_list[index], 'addSpecItemDisable', true)
      row.spec_item_list.push({
        item_name: '',
      })
      // this.addSpecItemDisable = true
    },
    // 删除规格
    delSpec(index, row) {
      console.log(row)
      this.$confirm('确认删除该规格？')
        .then(async (_) => {
          const { data: res } = await delSpec({
            spec_id: row.spec_id,
          })
          if (res.code === 1) {
            this.$message.success('删除成功！')
            this.refreshEditInfo(this.row)
          } else {
            this.$message.error(res.message)
            this.refreshEditInfo(this.row)
          }

          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    async addSpecitem(item, row, index) {
      console.log(row)
      console.log(item)
      if (item.editFlag === true) {
        // 编辑
        console.log('编辑')
        var reg = /^[0-9]{1,20}$/
        if (reg.test(item.item_name)) {
          this.$message.info('属性值不能只是数字')
          return
        }
        const { data: res } = await editSpecItem({
          spec_item_id: item.item_id,
          item: item.item_name,
        })
        if (res.code === 1) {
          this.refreshEditInfo(row)
        } else {
          this.$message.error(res.message)
          this.refreshEditInfo(row)
        }
      } else {
        // 添加
        let length = this.specForm.spec_list[index].spec_item_list.length
        //   let item_name = this.specForm.spec_list[index].spec_item_list[length]
        //     .item_name
        let item_name = this.specForm.spec_list[index].spec_item_list[
          length - 1
        ].item_name

        // console.log(length)
        var reg = /^[0-9]{1,20}$/
        if (reg.test(item_name)) {
          this.$message.info('属性值不能只是数字')
          return
        }
        const { data: res } = await addSpecItem({
          spec_id: row.spec_id,
          item_name,
        })
        console.log(res)
        if (res.code === 1) {
          this.refreshEditInfo(row)
        } else {
          this.$message.error(res.message)
          this.refreshEditInfo(row)
        }
      }
    },
    async handleTagClose(item) {
      console.log(item)
      const { data: res } = await delSpecItem({
        item_id: item.item_id,
      })
      // console.log(res);
      if (res.code === 1) {
        this.refreshEditInfo(this.row)
      } else {
        this.$message.error(res.message)
        this.refreshEditInfo(this.row)
      }
    },
    changeToInput(row, index) {
      //this.specNameEditFlag = true
      console.log(row)
      console.log(index)
      console.log(this.specForm.spec_list[index])
      // this.specForm.spec_list[index].editFlag = true
      this.$set(this.specForm.spec_list[index], 'editFlag', true)
    },
    changeTagIntoInput(item, row, index) {
      console.log(item)
      console.log(row)
      console.log(index)
      let item_id = item.item_id
      console.log(this.specForm.spec_list[index].spec_item_list)
      let tagArr = this.specForm.spec_list[index].spec_item_list
      tagArr.forEach((tag) => {
        if (tag.item_id === item_id) {
          this.$set(tag, 'editFlag', true)
        }
      })
    },
    async editSpecName(row, index) {
      if (row.editFlag === true) {
        // 编辑规格名
        const { data: res } = await editSpecName({
          spec_id: row.spec_id,
          spec_name: row.spec_name,
        })
        if (res.code === 1) {
          //  this.specNameEditFlag = false
          this.$set(this.specForm.spec_list[index], 'editFlag', false)
          this.refreshEditInfo(this.row)
        } else {
          //    this.specNameEditFlag = false
          this.$set(this.specForm.spec_list[index], 'editFlag', false)
          this.$message.error(res.message)
          this.refreshEditInfo(this.row)
        }
      } else {
        // 添加规格
        console.log('这是添加')
      }
    },
    async addSpecName(row, index) {
      console.log(row)
      if (row.addFlag === true) {
        const { data: res } = await addSpec({
          mould_id: this.row.mould_id,
          spec_name: row.spec_name,
        })
        if (res.code === 1) {
          //  this.specNameEditFlag = false
          // this.$set(this.specForm.spec_list[index], 'addFlag', false)
          this.refreshEditInfo(this.row)
          this.addSpecDisable = false
        } else {
          //    this.specNameEditFlag = false
          //  this.$set(this.specForm.spec_list[index], 'addFlag', false)
          this.$message.error(res.message)
          this.refreshEditInfo(this.row)
          this.addSpecDisable = false
        }
      }
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../assets/exclamationmark.png') no-repeat;
}
.spec-input {
  width: 100px;
}
.all {
  margin-bottom: 0 !important;
}
.inner-form-item {
  margin-bottom: 0 !important;
}
</style>
